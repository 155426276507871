import React, { useState, useEffect, useRef } from "react";
import { Input, notification } from "antd";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { textTypeCategory, textTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import {
    EditTwoTone,
    CheckCircleOutlined,
}
    from '@ant-design/icons';
import useVerifyOTPEmail from "../../api/VerifyOtpEmail";
import useRequestOTPEmail from "../../api/RequestOtpEmail";
import useCreateSchoolCode from "../../api/CreateSchoolCode";
import useCreateStudentCode from "../../api/CreateStudentCode";
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import { useDispatch, useSelector } from "react-redux";
import { setIsFormSubmitted } from "../../redux/student/studentSlice";

const EmailVerification = ( {
    onAnswerChange, userEmail, onNext, onBack, isTimeCompleted
} ) => {
    const [isEmailValid, setIsEmailValid] = useState( false );
    const [isOTPBoxVisible, setIsOTPBoxVisible] = useState( false );
    const [isEditable, setIsEditable] = useState( false );
    const [email, setEmail] = useState( "" );
    const [otpValues, setOtpValues] = useState( ["", "", "", ""] );
    const [isCheckIcon, setIsCheckIcon] = useState( false );
    const { client_name, id, grade } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const school_registration_route = dynamicData[client_name].school_registration_route;
    const student_registration_route = dynamicData[client_name].student_registration_route;
    const isFormSubmitted = useSelector( state => state.formSubmitted.isFormSubmitted );

    //const [savedEmail, setSavedEmail] = useState( 'default@email.com' );
    //const [userAddress, setUserAddress] = useState( 'DEMO12' );
    //const [contactPersonPhone, setContactPersonPhone] = useState( '1234567890' );
    //const [userSchoolName, setUserSchoolName] = useState( 'UserSchoolName' );
    //const [contactPersonName, setContactPersonName] = useState( "null" );

    const [isSendOTPClickable, setIsSendOTPClickable] = useState( true );
    const [isVerifyOTPClickable, setIsVerifyOTPClickable] = useState( false );

    useEffect( () => {
        setEmail( userEmail );
        setIsEmailValid( /^[a-zA-Z0-9]+([\.-]?[a-zA-Z0-9]+){0,5}@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/.test( userEmail ) );
    }, [userEmail] );

    const { emailData, isRequestingOTP, errorOTP, requestOTP } = useRequestOTPEmail();
    const { data, isLoading, error, verifyOTP } = useVerifyOTPEmail();
    const { createSchoolCode, response: createSchoolResponse, error: schoolError, isLoading: schoolCodeLoading } = useCreateSchoolCode();
    const { createStudentCode, response: createStudentResponse, error: studentError, isLoading: studentCodeLoading } = useCreateStudentCode();

    const location = useLocation();
    const query = new URLSearchParams( location.search );
    const institute_code = query.get( 'institute_id' ) || localStorage.getItem( 'schoolId' ) || "SCH-000";

    const user_email = localStorage.getItem( 'contact_person_email' ) || "user_email";
    const userAddress = localStorage.getItem( 'userAddress' ) || "user_address";
    const contactPersonPhone = localStorage.getItem( 'contact_person_phone' ) || "contact_person_phone";
    const contactPersonName = localStorage.getItem( 'contact_person_name' ) || "contact_person_name";
    const schoolName = localStorage.getItem( 'userSchoolName' ) || "school_name";

    const studentName = localStorage.getItem( 'studentName' ) || "student_name";
    const studentGrade = localStorage.getItem( 'studentGrade' ) || "student_grade";
    const studentEmail = localStorage.getItem( 'studentEmail' ) || "student_email";
    const responseId = localStorage.getItem( 'userAssessmentResponseID' ) || "1234";
    const studentDateOfBirth = localStorage.getItem( 'studentDateOfBirth' ) || "2024-01-01";
    const examinationMode = localStorage.getItem( 'examination_mode' ) || "online";

    useEffect( () => {
        if ( !isLoading ) {
            if ( data && !error ) {
                if ( id === school_registration_route ) {
                    createSchoolCode( schoolName, userAddress, contactPersonName, contactPersonPhone, user_email, examinationMode, responseId, client_name );
                    if ( createSchoolResponse && !schoolError ) {
                        notification.success( {
                            message: 'OTP Verified',
                            description: `Your OTP has been verified. Your school code is ${ createSchoolResponse.institute_code }`
                        } );
                        dispatch( setIsFormSubmitted( true ) );
                        localStorage.clear();
                    }
                } else if ( id === student_registration_route ) {
                    createStudentCode( studentName, studentGrade, studentEmail, institute_code, client_name, responseId, studentDateOfBirth )
                    if ( createStudentResponse && !studentError ) {
                        notification.success( {
                            message: 'OTP Verified',
                            description: `Your OTP has been verified. Your roll number is ${ createStudentResponse.student_code }`
                        } );
                        const grade = createStudentResponse.grade;
                        const student_id = createStudentResponse.id;
                        const student_name = createStudentResponse.name;
                        localStorage.setItem( 'student_roll_number', student_id );
                        localStorage.setItem( 'student_grade', grade );
                        localStorage.setItem( 'student_name', student_name )
                        dispatch( setIsFormSubmitted( true ) );
                        if ( client_name === "jc" ) {
                            Object.keys( localStorage ).forEach( ( key ) => {
                                if ( key !== 'student_grade' && key !== 'student_roll_number' && key !== 'student_name' ) {
                                    localStorage.removeItem( key );
                                }
                            } );
                        } else {
                            localStorage.clear();
                        }
                    }
                } else {
                    notification.success( {
                        message: 'OTP Verified',
                        description: 'Your OTP has been successfully verified.'
                    } );
                    dispatch( setIsFormSubmitted( true ) );
                    localStorage.clear();
                }
            } else if ( error ) {
                notification.error( {
                    message: 'OTP Verification Failed',
                    description: 'The OTP you entered is incorrect. Please try again.'
                } );
            }
        }
    }, [data, isLoading, error, createSchoolResponse, createStudentResponse] );

    const handleEditEmail = () => {
        setIsEditable( true );
        setIsCheckIcon( true );
        setIsSendOTPClickable( false );
        setIsOTPBoxVisible( false );
        setIsVerifyOTPClickable( false );
    };

    const handleCheckEmail = () => {
        if ( isEmailValid ) {
            setIsEditable( false );
            setIsCheckIcon( false );
            setIsSendOTPClickable( true );
        }
    };

    const handleSendOTP = async () => {
        onNext();
        setIsOTPBoxVisible( true );
        setIsSendOTPClickable( false );
        setIsVerifyOTPClickable( true );
        requestOTP( email, client_name );
    };

    const handleEmailChange = ( value ) => {
        onAnswerChange( value );
        setEmail( value );
        setIsEmailValid( /^[a-zA-Z0-9]+([\.-]?[a-zA-Z0-9]+){0,5}@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/.test( value ) );
    };

    const handleOTPChange = ( e, index ) => {
        const otp = e.target.value;

        if ( otp.length === 0 || /^[0-9]$/.test( otp ) ) {
            const newOtpValues = [...otpValues];
            newOtpValues[index] = otp;
            setOtpValues( newOtpValues );

            if ( otp.length === 0 && index > 0 ) {
                document.getElementById( `otp-input-${ index - 1 }` ).focus();
            } else if ( otp.length === 1 && index < 3 ) {
                document.getElementById( `otp-input-${ index + 1 }` ).focus();
            }
        }
    };

    const handleVerificationSubmit = async () => {
        const otp = otpValues.join( "" );
        // Make an API call to verify OTP
        verifyOTP( email, otp );
    };

    return (
        <>
            <div className={ `${ isFormSubmitted ? 'mt-[30%]' : '' }` }>
                <div className="mt-2">
                    <h1 className="font-poppins text-2xl font-medium bg-white mb-4 select-none">
                        Verify your Email
                    </h1>
                    <>
                        <input
                            disabled={ !isEditable }
                            className={ `w-1/2 rounded ${ isEditable ? " bg-white" : "bg-gray-300" } p-3 border-2 focus:border-blue-500` }
                            style={ { resize: "none" } }
                            value={ email }
                            onChange={ ( e ) => {
                                setEmail( e.target.value );
                                handleEmailChange( e.target.value );
                            } }
                        />
                        {
                            isCheckIcon ? (
                                <CheckCircleOutlined
                                    onClick={ handleCheckEmail }
                                    style={ { fontSize: '25px', cursor: isEmailValid ? 'pointer' : 'not-allowed' } }
                                    className={ `ml-4 ${ isEmailValid ? "#008000" : "#FF0000" }` }
                                />
                            ) : (
                                <EditTwoTone
                                    onClick={ handleEditEmail }
                                    style={ { fontSize: '25px' } }
                                    className="ml-4"
                                    twoToneColor="#2196F3"
                                />
                            )
                        }
                    </>
                </div>
                { isOTPBoxVisible && (
                    <>
                        <div className="flex text-center mt-2">
                            { otpValues.map( ( value, index ) => (
                                <Input
                                    key={ index }
                                    id={ `otp-input-${ index }` }
                                    className="mr-4 w-10 h-15"
                                    value={ value }
                                    maxLength={ 1 }
                                    onChange={ ( e ) => handleOTPChange( e, index ) }
                                />
                            ) ) }
                        </div>
                        <p className="font-poppins text-sm hover:text-red-500 font-medium mt-2">
                            Please enter the OTP sent to your Email
                        </p>
                    </>
                ) }
                <div className="flex flex-row gap-2 justify-end mt-4">
                    {
                        !isTimeCompleted ?
                            <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onBack }>
                                Back
                            </button>
                            :
                            <></>
                    }
                    <>
                        <button
                            className={ `px-4 py-2 rounded-md text-white ${ isEmailValid && isSendOTPClickable ? "bg-blue-500" : "bg-blue-300" }` }
                            onClick={ isEmailValid && isSendOTPClickable ? handleSendOTP : null }
                            style={ isEmailValid && isSendOTPClickable ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                        >
                            Send OTP
                        </button>
                        <button
                            className={ `px-4 py-2 rounded-md text-white ${ isVerifyOTPClickable ? "bg-green-500" : "bg-green-300" }` }
                            onClick={ isVerifyOTPClickable ? handleVerificationSubmit : null }
                            style={ isVerifyOTPClickable ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                        >
                            Verify
                        </button>
                    </>
                </div>
            </div>
        </>
    );
};

export default EmailVerification;
