import React, { useState, useEffect } from 'react';
import useAuthenticateStudent from '../../api/AuthenticateStudent';
import { DatePicker, notification, Spin, Input } from 'antd';
import { useMedia } from 'react-use';
import "../../css/style.css";
import "../../css/bootstrap.min.css";
import { useParams, useNavigate } from 'react-router-dom';
import { setIsFormSubmitted, setIsLoadingScreen } from '../../redux/student/studentSlice';
import { useDispatch } from 'react-redux';
import { dynamicData } from '../../helpers/data';
import useRequestOTPEmail from '../../api/RequestOtpEmail';
import useVerifyOTPEmail from '../../api/VerifyOtpEmail';
import useFetchStudentDataEmail from '../../api/FetchStudentDetailsEmail';

const SignInPage = () => {

    const [rollNumber, setRollNumber] = useState( '' );
    const [studentName, setStudentName] = useState( '' );
    const [email, setEmail] = useState( '' );
    const [dob, setDOB] = useState( '' );
    const [schoolCode, setSchoolCode] = useState( '' );
    const isWide = useMedia( '(min-width: 800px)' );
    const { client_name } = useParams();
    const verificationWithEmail = dynamicData[client_name].verify_with_email;


    const { emailData, isRequestingOTP, errorOTP, requestOTP } = useRequestOTPEmail();
    const { data, isLoading, error, verifyOTP } = useVerifyOTPEmail();
    const { response: studentData, isLoading: studentDataLoading, error: studentDataError, FetchStudentDataEmail } = useFetchStudentDataEmail();

    const [otpValues, setOtpValues] = useState( ["", "", "", ""] );
    const [showOtpBoxes, setShowOtpBoxes] = useState( false );

    const handleSignInEmail = () => {
        if ( email ) {
            FetchStudentDataEmail( email, client_name )
        }
    };

    //requestOTP( email, client_name );
    //setShowOtpBoxes( true );

    useEffect( () => {
        if ( studentData && !studentDataLoading ) {
            requestOTP( email, client_name )
            setShowOtpBoxes( true );
            const grade = studentData.data.grade;
            const student_id = studentData.data.id;
            const student_name = studentData.data.name;
            const userAssessmentResponseID = studentData.data.assessment_id;
            const assessment_status = studentData.data.assessment_status;
            const updatedAssessmentStatus = assessment_status === null ? 1 : assessment_status;
            if ( userAssessmentResponseID !== null ) {
                localStorage.setItem( 'userAssessmentResponseID', userAssessmentResponseID )
            }
            localStorage.setItem( 'student_roll_number', student_id );
            localStorage.setItem( 'student_name', student_name );
            localStorage.setItem( 'is_completed', updatedAssessmentStatus )

            if ( grade && !grade.includes( 'th' ) ) {
                const updatedGrade = grade + 'th';
                localStorage.setItem( 'student_grade', updatedGrade );
            } else {
                localStorage.setItem( 'student_grade', grade )
            }
        } else if ( studentDataError ) {
            notification.error( {
                message: 'Incorrect Email id',
                description: 'Please check your Email.'
            } );
        }
    }, [studentData, studentDataLoading] );

    const handleVerifyOtp = async () => {
        const otp = otpValues.join( "" );
        // Make an API call to verify OTP
        verifyOTP( email, otp );
        setShowOtpBoxes( false );
    };

    const handleOTPChange = ( e, index ) => {
        const otp = e.target.value;

        if ( otp.length === 0 || /^[0-9]$/.test( otp ) ) {
            const newOtpValues = [...otpValues];
            newOtpValues[index] = otp;
            setOtpValues( newOtpValues );

            if ( otp.length === 0 && index > 0 ) {
                document.getElementById( `otp-input-${ index - 1 }` ).focus();
            } else if ( otp.length === 1 && index < 3 ) {
                document.getElementById( `otp-input-${ index + 1 }` ).focus();
            }
        }
    };

    useEffect( () => {
        if ( !isLoading ) {
            if ( data && !error ) {
                notification.success( {
                    message: 'OTP Verified',
                    description: 'Your OTP has been successfully verified.'
                } );

                const student_grade = localStorage.getItem( 'student_grade' );
                const assessment_status = localStorage.getItem( 'is_completed' );
                console.log( 'assessment_status', assessment_status );

                if ( assessment_status === null || assessment_status !== '3' ) {
                    dispatch( setIsFormSubmitted( false ) );
                    navigate( `/${ client_name }/test/${ student_grade }/instructions` );
                } else {
                    dispatch( setIsFormSubmitted( true ) );
                    dispatch( setIsLoadingScreen( true ) );
                    notification.success( {
                        message: 'You\'ve already given the test.',
                    } );
                    navigate( `/${ client_name }/test/${ student_grade }/thank_you` );
                }
            } else if ( error ) {
                notification.error( {
                    message: 'OTP Verification Failed',
                    description: 'The OTP you entered is incorrect. Please try again.'
                } );
            }
        }
    }, [data, isLoading, error] );


    const handleAnswerChange = ( date ) => {
        setDOB( date );
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { AuthenticateStudent, response: AuthenticateStudentResponse, error: AuthenticateStudentError, isLoading: AuthenticateStudentLoading } = useAuthenticateStudent();

    const handleSignIn = async ( e ) => {
        e.preventDefault();
        const formattedDate = new Date( dob ).toISOString().split( 'T' )[0] || null;
        await AuthenticateStudent( schoolCode, formattedDate, rollNumber );
    };

    useEffect( () => {
        if ( dynamicData[client_name] ) {
            document.title = dynamicData[client_name].document_title;
            document.querySelector( 'meta[name="description"]' ).setAttribute( "content", dynamicData[client_name].document_description );
        }
    }, [client_name, dynamicData] );

    useEffect( () => {
        if ( AuthenticateStudentResponse ) {
            console.log( AuthenticateStudentResponse );
            const grade = AuthenticateStudentResponse.grade;
            const student_name = AuthenticateStudentResponse.name;
            const student_roll_number = AuthenticateStudentResponse.student_code;
            const assessment_status = AuthenticateStudentResponse.assessment_status;
            console.log( assessment_status )
            const userAssessmentResponseID = AuthenticateStudentResponse.assessment_id
            localStorage.setItem( 'student_name', student_name );
            localStorage.setItem( 'student_roll_number', student_roll_number );
            localStorage.setItem( 'is_completed', 1 );

            if ( assessment_status === null || ( assessment_status !== null && assessment_status !== 3 ) ) {
                Object.keys( localStorage ).forEach( ( key ) => {
                    if ( key !== 'student_name' && key !== 'student_roll_number' && key !== 'is_completed' ) {
                        localStorage.removeItem( key );
                    }
                } );
                dispatch( setIsFormSubmitted( false ) );
                notification.success( {
                    message: 'Sign In Successful',
                } );
                navigate( `/${ client_name }/test/${ grade }/instructions` );
            } else {
                localStorage.setItem( 'userAssessmentResponseID', userAssessmentResponseID )
                dispatch( setIsFormSubmitted( true ) );
                localStorage.setItem( 'isFormSubmitted', true )
                dispatch( setIsLoadingScreen( true ) );
                localStorage.setItem( 'isLoadingScreen', true )
                notification.success( {
                    message: 'You\'ve already given the test.',
                } );
                navigate( `/${ client_name }/test/${ grade }/thank_you` );
            }
        } else if ( AuthenticateStudentError ) {
            notification.error( {
                message: 'Sign In Failed',
                description: 'Invalid credentials. Please check your roll number, date of birth, and school code.',
            } );
        }
    }, [AuthenticateStudentResponse, AuthenticateStudentError] );

    return (
        <div>
            {
                !isWide ?
                    <div className="flex items-center justify-center h-screen">
                        <img src='https://i.ibb.co/6spSrPQ/mobile-viewing.png' alt='optimised' class="max-w-full max-h-full" />
                    </div>
                    :
                    <>
                        <div className="flex flex-col md:flex-row min-h-screen">
                            <div className="bg-gradient-to-r from-yellow-300 to-orange-400 flex flex-col justify-center items-center md:w-2/5">
                                {
                                    isWide ?
                                        <>
                                            <img className="mb-5" src="https://i.ibb.co/Vv2zyhR/jclogo.png" alt="logo" width="200" height="200" />
                                        </>
                                        :
                                        <></>
                                }
                                <img className={ `${ isWide ? "" : "" }` } src={ `${ isWide ? 'https://i.ibb.co/B3nMWzk/Frame-1000006445.png' : 'https://i.ibb.co/ZMk1bBh/icat-logo.png' }` } alt="auth-graphic" border="0" />
                            </div>
                            { AuthenticateStudentLoading ?
                                <div className="flex items-center justify-center w-1/2">
                                    <Spin tip="Loading" size="large" />
                                </div>
                                :
                                ( verificationWithEmail ?
                                    <>
                                        <div className="flex justify-center items-center w-full md:w-3/5 mt-3 mb-3">
                                            <div className="w-3/4 justify-center items-center px-4">
                                                <h1 className="text-2xl font-bold mb-1">Enter your registered email we will send a code to log you in</h1>
                                                <h1 className='text-xl font-semibold mt-2'>[Don't forget to check your Spam]</h1>
                                                <div className="flex flex-col mt-5">
                                                    <div className="mb-4 w-1/2">
                                                        <input
                                                            type="text"
                                                            id="email"
                                                            className="mt-1 p-3 block w-full border-2 border-black-300 rounded-md focus:ring-2 focus:border-blue-500"
                                                            required
                                                            value={ email }
                                                            onChange={ ( e ) => setEmail( e.target.value ) }
                                                            placeholder="Ex - abc@gmail.com"
                                                        />
                                                    </div>
                                                    { showOtpBoxes ? (
                                                        <div className="flex flex-col w-1/2">
                                                            <div className="flex flex-row w-3/4">
                                                                {
                                                                    otpValues.map( ( value, index ) => (
                                                                        <input
                                                                            key={ index }
                                                                            id={ `otp-input-${ index }` }
                                                                            className="w-3/4 mr-2 border-2 border-gray-300 rounded-md p-2 text-center focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition-all duration-300"
                                                                            value={ value }
                                                                            maxLength={ 1 }
                                                                            onChange={ ( e ) => handleOTPChange( e, index ) }
                                                                        />
                                                                    ) )
                                                                }
                                                            </div>
                                                            <button
                                                                className="w-full md:w-1/2 flex mt-3 justify-center py-3 px-5 border border-transparent rounded-md shadow-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                onClick={ handleVerifyOtp }
                                                            >
                                                                Verify OTP
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div className="flex w-1/2">
                                                            <button
                                                                type="submit"
                                                                className="w-full md:w-1/2 flex justify-center py-3 px-5 border border-transparent rounded-md shadow-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                onClick={ handleSignInEmail }
                                                            >
                                                                Send OTP
                                                            </button>
                                                        </div>
                                                    ) }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="flex justify-center items-center w-full md:w-3/5 mt-3 mb-3">
                                            <div className="w-full max-w-md justify-center items-center px-4">
                                                <h1 className="text-4xl font-bold mb-2">Sign In</h1>
                                                <p className="mb-10 text-base text-gray-400 font-light">Fill in the Following Details to Login to the Test.</p>
                                                <form className="space-y-8">
                                                    <div className="mb-4">
                                                        <label htmlFor="rollNumber" className="block text-base font-medium text-gray-400">
                                                            Roll number
                                                        </label>
                                                        <p className="mb-0 text-base text-gray-400 font-light">[Generated for You upon Registrations]</p>
                                                        <input
                                                            type="text"
                                                            id="rollNumber"
                                                            className="mt-1 p-3 block w-full border-2 border-black-300 rounded-md focus:ring-2 focus:border-blue-500"
                                                            required
                                                            value={ rollNumber }
                                                            onChange={ ( e ) => setRollNumber( e.target.value ) }
                                                            placeholder='Ex - 67493'
                                                        />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label htmlFor="dob" className="block text-base font-medium text-gray-400">
                                                            Date of birth
                                                        </label>
                                                        <DatePicker
                                                            className="sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
                                                            onChange={ handleAnswerChange }
                                                            value={ dob }
                                                            popupStyle={ {
                                                                width: '290px', height: '200px',
                                                            } }
                                                            showToday={ false }
                                                        />
                                                    </div>
                                                    <div className="mb-10">
                                                        <label htmlFor="schoolCode" className="block text-base font-medium text-gray-400">
                                                            School code
                                                        </label>
                                                        <p className="mb-0 text-base text-gray-400 font-light">[Provided to You by Your School] </p>
                                                        <input
                                                            type="text"
                                                            id="schoolCode"
                                                            className="mt-1 block p-3 w-full border-2 border-black-300 rounded-md focus:ring-2 focus:border-blue-500"
                                                            required
                                                            value={ schoolCode }
                                                            onChange={ ( e ) => setSchoolCode( e.target.value ) }
                                                            placeholder='Ex - 254'
                                                        />
                                                    </div>
                                                    <div className="flex justify-center">
                                                        <button
                                                            type="submit"
                                                            className="w-full md:w-1/2 flex justify-center py-3 px-5 border border-transparent rounded-md shadow-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                            onClick={ handleSignIn }
                                                        >
                                                            Sign in
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </>
            }
        </div>
    );
};

export default SignInPage;




