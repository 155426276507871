import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { textTypeCategory, textTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import '../../styles.css';
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams, useLocation } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";

const TextInputStep = ( { question, answer, onAnswerChange, onNext, onBack } ) => {
  const [isAnswerFilled, setIsAnswerFilled] = useState( false );
  const [firstName, setFirstName] = useState( "" );

  const location = useLocation();
  const query = new URLSearchParams( location.search );
  const institute_code = query.get( 'institute_id' );

  useEffect( () => {
    if ( answer ) {
      setFirstName( answer );
      setIsAnswerFilled( true )
    } else if ( question.text.includes( "Check your school name" ) ) {
      setIsAnswerFilled( true )
      setFirstName( localStorage.getItem( 'schoolName' ) )
    } else {
      setIsAnswerFilled( false )
    }
  }, [question, answer] )


  const handleKeyDownName = ( e ) => {
    // Prevent input of numeric values and special characters (except space)
    const regex = /^[A-Za-z\s]+$/;
    if ( !regex.test( e.key ) ) {
      e.preventDefault();
    }
    TrackGoogleAnalyticsEvent( textTypeCategory, textTypeAction );
  };

  const handleAnswerChange = ( value ) => {
    onAnswerChange( value );
    setFirstName( value );
    setIsAnswerFilled( value.trim() !== "" );

    if ( question.text.includes( "Enter Your School Name" ) ) {
      localStorage.setItem( 'userSchoolName', value );
    } else if ( question.text.includes( "Who will serve as the iCAT coordinator from your school?" ) ) {
      localStorage.setItem( "contact_person_name", value )
    } else if ( question.text.includes( "Your report is ready! 📝\nJust one last step!\n What should we call you?" ) ) {
      localStorage.setItem( "studentName", value )
    }
  };

  const { client_name, id } = useParams();

  const isPagination = dynamicData[client_name].is_pagination;
  const is_question_navigation = dynamicData[client_name].is_question_navigation &&
    window.location.href.includes( 'test' );
  const student_registration_route = dynamicData[client_name].student_registration_route;

  return (
    <>
      <div className="mt-2">
        <div className="justify-content">
          <QuestionAndDescription question={ question } />
          <div>
            <input
              className="sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
              style={ { resize: "none", textAlign: "left" } }
              value={
                question.text.includes( "Check your school name" )
                  ? localStorage.getItem( 'schoolName' )
                  : firstName
              }
              onChange={ ( e ) =>
                //setFirstName( e.target.value );
                handleAnswerChange( e.target.value )
              }
              onKeyDown={ handleKeyDownName }
              placeholder={ question?.placeholder_text }
              disabled={ id === student_registration_route && question.text.includes( "Check your school name" ) }
            />
          </div>
        </div>
      </div>
      {
        isPagination ?
          <></>
          :
          is_question_navigation ?
            <>
              <SubmitButton onNext={ onNext } />
            </>
            :
            <div className="flex flex-row gap-2 justify-end mt-4">
              {
                question?.question_optional === "optional" && (
                  <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onNext } >
                    Skip
                  </button>
                )
              }
              <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onBack }>
                Back
              </button>
              <button
                className={ `px-4 py-2 rounded-md text-white ${ !isAnswerFilled ? "bg-blue-300" : "bg-blue-500" }` }
                onClick={ !isAnswerFilled ? null : onNext }
                style={ !isAnswerFilled ? { cursor: "not-allowed" } : { cursor: "pointer" } }
              >
                Next
              </button>
            </div>
      }
    </>
  );
};

export default TextInputStep;
