import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { dynamicData } from '../../helpers/data';

const ThankyouAIExpertsEdge = () => {

    const { client_name } = useParams();

    const thankYouContent = dynamicData[client_name].thank_you_page;

    const heading = thankYouContent.heading;
    const sub_heading_one = thankYouContent.sub_heading_one;
    const sub_heading_two = thankYouContent.sub_heading_two;
    const sub_heading_three = thankYouContent.sub_heading_three;

    const userName = localStorage.getItem( 'studentName' );
    const userId = localStorage.getItem( 'userAssessmentResponseID' );

    const bookSession = () => {
        if ( typeof window !== "undefined" ) {
            if ( window.fbq != null ) {
                window.fbq( 'track', 'pageview' )
            }
        }
        window.open( 'https://bookings.aiexpertsedge.com/admin/mentorship-session', '_blank' );
    }

    const goToReports = () => {
        if ( typeof window !== "undefined" ) {
            if ( window.fbq != null ) {
                window.fbq( 'track', 'pageview' )
            }
        }
        window.open( `https://reports.aiexpertsedge.com/?userName=${ userName }&userId=${ userId }`, '_blank' );
    }

    return (
        <div className="p-8 shadow-lg rounded-lg max-w-xl mx-auto text-center text-white sm:p-4 xl:p-8">

            <h1 className="text-3xl font-bold text-black mb-4 sm:text-2xl xl:text-3xl">{ heading }</h1>

            <p className="text-lg  text-[#283972] font-semibold mb-6 sm:text-base xl:text-lg">{ sub_heading_one }</p>

            <h1 className="text-3xl text-black font-bold mb-4 sm:text-base xl:text-2xl">{ sub_heading_two }</h1>

            <p className="text-lg text-[#283972] font-semibold mb-8 sm:text-base xl:text-lg">{ sub_heading_three }</p>


            <div className="flex justify-center gap-5">
                <button onClick={ goToReports } className="block bg-[#283972] text-white w-1/2 text-center font-semibold rounded py-3 px-6 mb-2 sm:w-full xl:w-1/2">
                    Go to Reports
                </button>
            </div>
        </div>
    );
};

export default ThankyouAIExpertsEdge

